/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionPaymentInstrumentProcess
 */
export interface TransactionPaymentInstrumentProcess {
    /**
     * process status
     * @type {string}
     * @memberof TransactionPaymentInstrumentProcess
     */
    status: TransactionPaymentInstrumentProcessStatus;
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentInstrumentProcess
     */
    paymentInstrumentId?: string;
}


/**
 * @export
 */
export const TransactionPaymentInstrumentProcessStatus = {
    Created: 'created',
    Completed: 'completed'
} as const;
export type TransactionPaymentInstrumentProcessStatus = typeof TransactionPaymentInstrumentProcessStatus[keyof typeof TransactionPaymentInstrumentProcessStatus];


/**
 * Check if a given object implements the TransactionPaymentInstrumentProcess interface.
 */
export function instanceOfTransactionPaymentInstrumentProcess(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function TransactionPaymentInstrumentProcessFromJSON(json: any): TransactionPaymentInstrumentProcess {
    return TransactionPaymentInstrumentProcessFromJSONTyped(json, false);
}

export function TransactionPaymentInstrumentProcessFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionPaymentInstrumentProcess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'paymentInstrumentId': !exists(json, 'paymentInstrumentId') ? undefined : json['paymentInstrumentId'],
    };
}

export function TransactionPaymentInstrumentProcessToJSON(value?: TransactionPaymentInstrumentProcess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'paymentInstrumentId': value.paymentInstrumentId,
    };
}

