/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionTrafficProviderConfig
 */
export interface TransactionTrafficProviderConfig {
    /**
     * Traffic provider brand color(s) to override
     * @type {string}
     * @memberof TransactionTrafficProviderConfig
     */
    brand10?: string;
    /**
     * Traffic provider brand color(s) to override
     * @type {string}
     * @memberof TransactionTrafficProviderConfig
     */
    brand110?: string;
    /**
     * Traffic provider brand color(s) to override
     * @type {string}
     * @memberof TransactionTrafficProviderConfig
     */
    brand120?: string;
    /**
     * Traffic provider brand color(s) to override
     * @type {string}
     * @memberof TransactionTrafficProviderConfig
     */
    brand150?: string;
    /**
     * Traffic provider brand color(s) to override
     * @type {string}
     * @memberof TransactionTrafficProviderConfig
     */
    brand1100?: string;
    /**
     * Traffic provider brand color(s) to override
     * @type {string}
     * @memberof TransactionTrafficProviderConfig
     */
    brand1110?: string;
    /**
     * Traffic provider brand color(s) to override
     * @type {string}
     * @memberof TransactionTrafficProviderConfig
     */
    brand1120?: string;
    /**
     * Traffic provider illustration color to override
     * @type {string}
     * @memberof TransactionTrafficProviderConfig
     */
    illustrationsbrand1?: string;
    /**
     * Traffic provider rounded corner radius (percentage of element height) for buttons to override, possible values (0;50]
     * @type {string}
     * @memberof TransactionTrafficProviderConfig
     */
    corner50?: string;
    /**
     * Traffic provider shadow color 1 selected to override
     * @type {string}
     * @memberof TransactionTrafficProviderConfig
     */
    shadow1selected?: string;
}

/**
 * Check if a given object implements the TransactionTrafficProviderConfig interface.
 */
export function instanceOfTransactionTrafficProviderConfig(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TransactionTrafficProviderConfigFromJSON(json: any): TransactionTrafficProviderConfig {
    return TransactionTrafficProviderConfigFromJSONTyped(json, false);
}

export function TransactionTrafficProviderConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionTrafficProviderConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'brand10': !exists(json, 'brand10') ? undefined : json['brand10'],
        'brand110': !exists(json, 'brand110') ? undefined : json['brand110'],
        'brand120': !exists(json, 'brand120') ? undefined : json['brand120'],
        'brand150': !exists(json, 'brand150') ? undefined : json['brand150'],
        'brand1100': !exists(json, 'brand1100') ? undefined : json['brand1100'],
        'brand1110': !exists(json, 'brand1110') ? undefined : json['brand1110'],
        'brand1120': !exists(json, 'brand1120') ? undefined : json['brand1120'],
        'illustrationsbrand1': !exists(json, 'illustrationsbrand1') ? undefined : json['illustrationsbrand1'],
        'corner50': !exists(json, 'corner50') ? undefined : json['corner50'],
        'shadow1selected': !exists(json, 'shadow1selected') ? undefined : json['shadow1selected'],
    };
}

export function TransactionTrafficProviderConfigToJSON(value?: TransactionTrafficProviderConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'brand10': value.brand10,
        'brand110': value.brand110,
        'brand120': value.brand120,
        'brand150': value.brand150,
        'brand1100': value.brand1100,
        'brand1110': value.brand1110,
        'brand1120': value.brand1120,
        'illustrationsbrand1': value.illustrationsbrand1,
        'corner50': value.corner50,
        'shadow1selected': value.shadow1selected,
    };
}

