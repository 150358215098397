<template>
  <div class="form-widget-money-field">
    <SWidgetInputMoney
      v-model:amount="amountValue"
      v-model:currency="currencyValue"
      :amount-input-id="amountId"
      :currency-select-id="currencyId"
      :currencies="currencies"
      :readonly="readonly"
      :class="{ 'is-invalid': fieldErrors.length > 0 }"
    >
      <template v-for="(_, slot, i) in $slots" #[slot] :key="i">
        <slot :name="slot"></slot>
      </template>
    </SWidgetInputMoney>
    <div>
      <template v-if="fieldErrors.length === 0">
        <FormPlaceholderMessage />
      </template>
      <SFormMessage v-else :text="fieldErrors[0]" :data-testid="`error-message-${currencyId}`" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { computed } from 'vue';
import { SFormMessage, SWidgetInputMoney } from '@simplex/simplex-ui';
import FormPlaceholderMessage from './FormPlaceholderMessage.vue';

const props = defineProps<{
  amountId: string;
  currencyId: string;
  currencies: InstanceType<typeof SWidgetInputMoney>['$props']['currencies'];
  readonly?: boolean;
}>();

const { value: amountValue, errors: amountErrors } = useField<string>(() => props.amountId, undefined);
const { value: currencyValue, errors: currencyErrors } = useField<string>(() => props.currencyId, undefined);

const fieldErrors = computed(() => [amountErrors.value, currencyErrors.value].flatMap((e) => e));
</script>

<style scoped lang="postcss">
.form-widget-money-field {
  width: 100%;
}
</style>
