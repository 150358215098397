import { BigNumber } from 'bignumber.js';
import { z } from 'zod';
import { type TFunction } from '@simplex/ui.translations';
import type { Config } from '../app/config/config';
import type { CurrenciesConfig } from './currencies';

export function schema(t: TFunction, config: Config, currenciesConfigs: CurrenciesConfig) {
  return z
    .object({
      cryptoAmount: z
        .string({ required_error: t('widget.validation.required', { fieldName: t('widget.sell-title') }) })
        .refine((v) => !BigNumber(v).isNaN(), t('widget.validation.should-be-number')),
      cryptoCurrency: z.string(),
      fiatAmount: z
        .string()
        .refine(
          (v) => !BigNumber(v).isLessThanOrEqualTo(BigNumber(config.FIAT_MIN_AMOUNT)),
          (v) => ({
            message: t('widget.validation.should-be-greater', {
              amount: v,
              minValue: config.FIAT_MIN_AMOUNT,
              fiatCurrency: config.DEFAULTS.fiatCurrency,
            }),
          }),
        )
        .refine(
          (v) => !BigNumber(v).isGreaterThanOrEqualTo(BigNumber(config.FIAT_MAX_AMOUNT)),
          (v) => ({
            message: t('widget.validation.should-be-less', {
              amount: v,
              maxValue: config.FIAT_MAX_AMOUNT,
              fiatCurrency: config.DEFAULTS.fiatCurrency,
            }),
          }),
        ),
      fiatCurrency: z.string(),
    })
    .superRefine(({ cryptoAmount, cryptoCurrency }, refinementContext) => {
      const currentlySelectedCryptoConfig = currenciesConfigs.cryptoCurrencies.find(
        (c) => c.currencySelectOption.value === cryptoCurrency,
      );

      const regexp = currentlySelectedCryptoConfig?.rounding;
      if (regexp) {
        if (!regexp.test(cryptoAmount)) {
          return refinementContext.addIssue({
            code: z.ZodIssueCode.invalid_string,
            validation: 'regex',
            message: t('widget.validation.enter-valid-number'),
            path: ['cryptoAmount'],
          });
        }
      }
    });
}

export type FormValues = Required<z.infer<ReturnType<typeof schema>>>;
