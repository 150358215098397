<template>
  <form class="widget-form">
    <div class="inputs">
      <div class="sell">
        <div class="action-title">
          <SHeading2>{{ $t('widget.sell-title') }}</SHeading2>
        </div>
        <FormWidgetMoneyField
          data-testid="crypto-money"
          :readonly="loading"
          class="money"
          amount-id="cryptoAmount"
          currency-id="cryptoCurrency"
          :currencies="supportedCryptoCurrencies"
        />
      </div>
      <div class="get" data-testid="get-container">
        <div class="action-title">
          <SHeading2>{{ $t('widget.get-title') }}</SHeading2>
        </div>
        <FormWidgetMoneyField
          class="money"
          data-testid="fiat-money"
          amount-id="fiatAmount"
          currency-id="fiatCurrency"
          readonly
          :currencies="supportedFiatCurrencies"
        />
      </div>
      <FormPlaceholderMessage v-if="!message" class="message" />
      <SFormMessage v-else class="message" :class="{ loading: loading }" :text="message" />
    </div>
  </form>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { SFormMessage, SHeading2, type SWidgetInputMoneyCurrency } from '@simplex/simplex-ui';
import { useTheme } from '../app/use-theme';
import FormPlaceholderMessage from '../lib/forms/FormPlaceholderMessage.vue';
import FormWidgetMoneyField from '../lib/forms/FormWidgetMoneyField.vue';

const props = defineProps<{
  loading: boolean;
  formMessage?: string;
  supportedCryptoCurrencies: SWidgetInputMoneyCurrency[];
  supportedFiatCurrencies: SWidgetInputMoneyCurrency[];
}>();

const theme = useTheme();
const { t } = useI18n();

const message = computed(() => {
  if (props.loading) {
    return t('widget.loading');
  }
  return props.formMessage;
});
</script>

<style scoped lang="postcss">
@import '../assets/breakpoints';

.widget-form {
  height: 100%;
  background-color: v-bind("theme['system-0']");
  display: flex;
  flex-direction: column;
  width: 100%;

  > .inputs {
    padding: 32px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1 1 auto;

    > .sell,
    > .get {
      display: flex;

      > .action-title {
        height: 48.8px;
        display: flex;
        flex: 0 0 40px;
        align-items: center;
      }

      > .money {
        margin-left: 16px;

        @media (--md) {
          margin-left: 32px;
        }
      }
    }

    .message {
      align-self: center;
    }
  }
}
</style>
