<template>
  <div data-testid="app">
    <PageWidget />
    <ErrorScreen v-if="uncaughtError" :retry="reload" @close-clicked="() => (uncaughtError = false)" />
  </div>
</template>

<script setup lang="ts">
import { onErrorCaptured, ref } from 'vue';
import ErrorScreen from './errors/ErrorScreen.vue';
import PageWidget from './widget/PageWidget.vue';

const uncaughtError = ref(false);

onErrorCaptured((err) => {
  uncaughtError.value = true;
  newrelic.noticeError(err);
  return true;
});

function reload() {
  window.location.href = '/';
}
</script>
