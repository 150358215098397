export function pushMessageForInitiateSell(window: Window, txnUrl: string) {
  !!window?.parent?.postMessage &&
    window.parent.postMessage(
      {
        action: 'redirect',
        data: {
          txnUrl,
        },
      },
      '*',
    );
}

export function listenForErrorMessage(window: Window, onError: () => void) {
  window.addEventListener(
    'message',
    (
      event: MessageEvent<{
        action: 'error';
        data: {
          code: string;
        };
      }>,
    ) => {
      if (event.data.action === 'error') {
        onError();
      }
    },
    false,
  );
}
