/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccountDetailsPersonalDetails } from './AccountDetailsPersonalDetails';
import {
    AccountDetailsPersonalDetailsFromJSON,
    AccountDetailsPersonalDetailsFromJSONTyped,
    AccountDetailsPersonalDetailsToJSON,
} from './AccountDetailsPersonalDetails';
import type { AccountDetailsWebSessionsInner } from './AccountDetailsWebSessionsInner';
import {
    AccountDetailsWebSessionsInnerFromJSON,
    AccountDetailsWebSessionsInnerFromJSONTyped,
    AccountDetailsWebSessionsInnerToJSON,
} from './AccountDetailsWebSessionsInner';

/**
 * 
 * @export
 * @interface InitiateSellAcountDetails
 */
export interface InitiateSellAcountDetails {
    /**
     * The identifier you use for the end-user’s account.
     * @type {string}
     * @memberof InitiateSellAcountDetails
     */
    accountId: string;
    /**
     * A list of sessions/logins in the account, each with at least an IP and a timestamp.
     * @type {Array<AccountDetailsWebSessionsInner>}
     * @memberof InitiateSellAcountDetails
     */
    webSessions?: Array<AccountDetailsWebSessionsInner>;
    /**
     * 
     * @type {AccountDetailsPersonalDetails}
     * @memberof InitiateSellAcountDetails
     */
    personalDetails?: AccountDetailsPersonalDetails;
}

/**
 * Check if a given object implements the InitiateSellAcountDetails interface.
 */
export function instanceOfInitiateSellAcountDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accountId" in value;

    return isInstance;
}

export function InitiateSellAcountDetailsFromJSON(json: any): InitiateSellAcountDetails {
    return InitiateSellAcountDetailsFromJSONTyped(json, false);
}

export function InitiateSellAcountDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitiateSellAcountDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accountId': json['account_id'],
        'webSessions': !exists(json, 'web_sessions') ? undefined : ((json['web_sessions'] as Array<any>).map(AccountDetailsWebSessionsInnerFromJSON)),
        'personalDetails': !exists(json, 'personal_details') ? undefined : AccountDetailsPersonalDetailsFromJSON(json['personal_details']),
    };
}

export function InitiateSellAcountDetailsToJSON(value?: InitiateSellAcountDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_id': value.accountId,
        'web_sessions': value.webSessions === undefined ? undefined : ((value.webSessions as Array<any>).map(AccountDetailsWebSessionsInnerToJSON)),
        'personal_details': AccountDetailsPersonalDetailsToJSON(value.personalDetails),
    };
}

