import type { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import type { ApiError } from '@simplex/ui.errors';

type SetFieldError = ReturnType<typeof useForm>['setFieldError'];

export function useFieldValidation() {
  const { t } = useI18n();

  const setAmountTooLowError = (setFieldError: SetFieldError, error: ApiError) => {
    const fields = getProblemFields(error);
    setFieldError(
      'cryptoAmount',
      t('widget.validation.error-amount-too-low', {
        minValue: fields?.min_amount,
      }),
    );
    if (fields?.currency && fields?.min_amount && fields?.amount) {
      setFieldError(
        'fiatAmount',
        t('widget.validation.should-be-greater', {
          amount: fields?.amount,
          minValue: fields?.min_amount,
          fiatCurrency: fields?.currency,
        }),
      );
    }
  };

  const setAmountTooHightError = (setFieldError: SetFieldError, error: ApiError) => {
    const fields = getProblemFields(error);
    setFieldError(
      'cryptoAmount',
      t('widget.validation.error-amount-too-high', {
        maxValue: fields?.max_amount,
      }),
    );
    if (fields?.currency && fields?.max_amount && fields?.amount) {
      setFieldError(
        'fiatAmount',
        t('widget.validation.should-be-less', {
          amount: fields?.amount,
          maxValue: fields?.max_amount,
          fiatCurrency: fields?.currency,
        }),
      );
    }
  };

  const setAllFieldsInvalidAmountError = (setFieldError: SetFieldError) => {
    setFieldError('cryptoAmount', t('widget.validation.error-invalid-amount'));
    setFieldError('fiatAmount', t('widget.validation.error-invalid-fiat-amount'));
  };

  return {
    setAmountTooHightError,
    setAmountTooLowError,
    setAllFieldsInvalidAmountError,
  };
}

const getProblemFields = (error: ApiError) => {
  return error.details?.fields as Record<string, unknown>;
};
