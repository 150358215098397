/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TransactionLiquidityProvider } from './TransactionLiquidityProvider';
import {
    TransactionLiquidityProviderFromJSON,
    TransactionLiquidityProviderFromJSONTyped,
    TransactionLiquidityProviderToJSON,
} from './TransactionLiquidityProvider';

/**
 * 
 * @export
 * @interface TransactionOffer
 */
export interface TransactionOffer {
    /**
     * 
     * @type {string}
     * @memberof TransactionOffer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionOffer
     */
    transactionId: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionOffer
     */
    paymentMethod: string;
    /**
     * 
     * @type {TransactionLiquidityProvider}
     * @memberof TransactionOffer
     */
    liquidityProvider: TransactionLiquidityProvider;
    /**
     * payment amount. In major units. I.e. 3.55
     * @type {string}
     * @memberof TransactionOffer
     */
    processingFee: string;
    /**
     * Crypto currency with optional network identifier attached.
     * List of crypto currencies Simplex might send you:
     * - USDT
     * - TRX
     * - BTC
     * - USDP
     * - AAVE
     * - USDC
     * - UNI
     * - COTI-ERC20
     * - TUSD
     * - COTI
     * - BUSD
     * - HTR
     * - USDT-TRC20
     * @type {string}
     * @memberof TransactionOffer
     */
    cryptoCurrency: string;
    /**
     * Three-letter ISO currency code https://www.iso.org/iso-4217-currency-codes.html
     * @type {string}
     * @memberof TransactionOffer
     */
    fiatCurrency: string;
    /**
     * crypto amount. In major units. I.e. 3.4565432344
     * @type {string}
     * @memberof TransactionOffer
     */
    cryptoAmount: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionOffer
     */
    cryptoDecimals: number;
    /**
     * payment amount. In major units. I.e. 3.55
     * @type {string}
     * @memberof TransactionOffer
     */
    totalAmount: string;
    /**
     * payment amount. In major units. I.e. 3.55
     * @type {string}
     * @memberof TransactionOffer
     */
    totalAmountWithoutFees: string;
    /**
     * Crypto icon URL
     * @type {string}
     * @memberof TransactionOffer
     */
    cryptoCurrencyLogoUrl: string;
    /**
     * Crypto exchange rate
     * @type {string}
     * @memberof TransactionOffer
     */
    exchangeRate: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionOffer
     */
    processingTime: TransactionOfferProcessingTime;
}


/**
 * @export
 */
export const TransactionOfferProcessingTime = {
    Instant: 'instant',
    Fast: 'fast',
    Slow: 'slow'
} as const;
export type TransactionOfferProcessingTime = typeof TransactionOfferProcessingTime[keyof typeof TransactionOfferProcessingTime];


/**
 * Check if a given object implements the TransactionOffer interface.
 */
export function instanceOfTransactionOffer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "transactionId" in value;
    isInstance = isInstance && "paymentMethod" in value;
    isInstance = isInstance && "liquidityProvider" in value;
    isInstance = isInstance && "processingFee" in value;
    isInstance = isInstance && "cryptoCurrency" in value;
    isInstance = isInstance && "fiatCurrency" in value;
    isInstance = isInstance && "cryptoAmount" in value;
    isInstance = isInstance && "cryptoDecimals" in value;
    isInstance = isInstance && "totalAmount" in value;
    isInstance = isInstance && "totalAmountWithoutFees" in value;
    isInstance = isInstance && "cryptoCurrencyLogoUrl" in value;
    isInstance = isInstance && "exchangeRate" in value;
    isInstance = isInstance && "processingTime" in value;

    return isInstance;
}

export function TransactionOfferFromJSON(json: any): TransactionOffer {
    return TransactionOfferFromJSONTyped(json, false);
}

export function TransactionOfferFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionOffer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'transactionId': json['transactionId'],
        'paymentMethod': json['paymentMethod'],
        'liquidityProvider': TransactionLiquidityProviderFromJSON(json['liquidityProvider']),
        'processingFee': json['processingFee'],
        'cryptoCurrency': json['cryptoCurrency'],
        'fiatCurrency': json['fiatCurrency'],
        'cryptoAmount': json['cryptoAmount'],
        'cryptoDecimals': json['cryptoDecimals'],
        'totalAmount': json['totalAmount'],
        'totalAmountWithoutFees': json['totalAmountWithoutFees'],
        'cryptoCurrencyLogoUrl': json['cryptoCurrencyLogoUrl'],
        'exchangeRate': json['exchangeRate'],
        'processingTime': json['processingTime'],
    };
}

export function TransactionOfferToJSON(value?: TransactionOffer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'transactionId': value.transactionId,
        'paymentMethod': value.paymentMethod,
        'liquidityProvider': TransactionLiquidityProviderToJSON(value.liquidityProvider),
        'processingFee': value.processingFee,
        'cryptoCurrency': value.cryptoCurrency,
        'fiatCurrency': value.fiatCurrency,
        'cryptoAmount': value.cryptoAmount,
        'cryptoDecimals': value.cryptoDecimals,
        'totalAmount': value.totalAmount,
        'totalAmountWithoutFees': value.totalAmountWithoutFees,
        'cryptoCurrencyLogoUrl': value.cryptoCurrencyLogoUrl,
        'exchangeRate': value.exchangeRate,
        'processingTime': value.processingTime,
    };
}

