<template>
  <SModal
    :title="$t('common.unknown-error')"
    intent="info"
    cta="approval"
    :close-icon="isCloseable"
    :approval-button-label="$t('common.try-again')"
    @approve="onRetry"
    @close="$emit('closeClicked')"
  >
    <template #visual>
      <SIconGeneralSad width="72" height="72" />
    </template>
    <template #content>
      <SSpan>{{ $t('common.unknown-error-description') }}</SSpan>
    </template>
  </SModal>
</template>

<script setup lang="ts">
import { SIconGeneralSad, SModal, SSpan } from '@simplex/simplex-ui';
import { useRetry } from './use-retry';

const props = defineProps<{
  retry: () => Promise<void> | void | Promise<unknown>;
  isCloseable?: boolean;
}>();

defineEmits<{
  (e: 'closeClicked'): void;
}>();

const { onRetry } = useRetry(props.retry);
</script>
