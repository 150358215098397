/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionPaymentInstrumentRecollectionProcess
 */
export interface TransactionPaymentInstrumentRecollectionProcess {
    /**
     * 
     * @type {string}
     * @memberof TransactionPaymentInstrumentRecollectionProcess
     */
    id: string;
    /**
     * process status
     * @type {string}
     * @memberof TransactionPaymentInstrumentRecollectionProcess
     */
    status: TransactionPaymentInstrumentRecollectionProcessStatus;
    /**
     * The email/phone value OTP will be sent to. Obfuscated for privacy reasons.
     * @type {string}
     * @memberof TransactionPaymentInstrumentRecollectionProcess
     */
    otpRecipientHint: string;
    /**
     * The hint to the user about the failed payment instrument. Obfuscated for privacy reasons.
     * @type {string}
     * @memberof TransactionPaymentInstrumentRecollectionProcess
     */
    failedPaymentInstrumentHint: string;
}


/**
 * @export
 */
export const TransactionPaymentInstrumentRecollectionProcessStatus = {
    Initiated: 'initiated',
    Completed: 'completed'
} as const;
export type TransactionPaymentInstrumentRecollectionProcessStatus = typeof TransactionPaymentInstrumentRecollectionProcessStatus[keyof typeof TransactionPaymentInstrumentRecollectionProcessStatus];


/**
 * Check if a given object implements the TransactionPaymentInstrumentRecollectionProcess interface.
 */
export function instanceOfTransactionPaymentInstrumentRecollectionProcess(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "otpRecipientHint" in value;
    isInstance = isInstance && "failedPaymentInstrumentHint" in value;

    return isInstance;
}

export function TransactionPaymentInstrumentRecollectionProcessFromJSON(json: any): TransactionPaymentInstrumentRecollectionProcess {
    return TransactionPaymentInstrumentRecollectionProcessFromJSONTyped(json, false);
}

export function TransactionPaymentInstrumentRecollectionProcessFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionPaymentInstrumentRecollectionProcess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': json['status'],
        'otpRecipientHint': json['otpRecipientHint'],
        'failedPaymentInstrumentHint': json['failedPaymentInstrumentHint'],
    };
}

export function TransactionPaymentInstrumentRecollectionProcessToJSON(value?: TransactionPaymentInstrumentRecollectionProcess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': value.status,
        'otpRecipientHint': value.otpRecipientHint,
        'failedPaymentInstrumentHint': value.failedPaymentInstrumentHint,
    };
}

