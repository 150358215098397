/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CurrenciesFiatCurrenciesInner
 */
export interface CurrenciesFiatCurrenciesInner {
    /**
     * Three-letter ISO currency code https://www.iso.org/iso-4217-currency-codes.html
     * @type {string}
     * @memberof CurrenciesFiatCurrenciesInner
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CurrenciesFiatCurrenciesInner
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CurrenciesFiatCurrenciesInner
     */
    decimals: number;
    /**
     * 
     * @type {string}
     * @memberof CurrenciesFiatCurrenciesInner
     */
    imageUrl: string;
    /**
     * 
     * @type {number}
     * @memberof CurrenciesFiatCurrenciesInner
     */
    minAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrenciesFiatCurrenciesInner
     */
    maxAmount?: number;
}

/**
 * Check if a given object implements the CurrenciesFiatCurrenciesInner interface.
 */
export function instanceOfCurrenciesFiatCurrenciesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "decimals" in value;
    isInstance = isInstance && "imageUrl" in value;

    return isInstance;
}

export function CurrenciesFiatCurrenciesInnerFromJSON(json: any): CurrenciesFiatCurrenciesInner {
    return CurrenciesFiatCurrenciesInnerFromJSONTyped(json, false);
}

export function CurrenciesFiatCurrenciesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrenciesFiatCurrenciesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'name': json['name'],
        'decimals': json['decimals'],
        'imageUrl': json['imageUrl'],
        'minAmount': !exists(json, 'minAmount') ? undefined : json['minAmount'],
        'maxAmount': !exists(json, 'maxAmount') ? undefined : json['maxAmount'],
    };
}

export function CurrenciesFiatCurrenciesInnerToJSON(value?: CurrenciesFiatCurrenciesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'decimals': value.decimals,
        'imageUrl': value.imageUrl,
        'minAmount': value.minAmount,
        'maxAmount': value.maxAmount,
    };
}

