import { BigNumber } from 'bignumber.js';

export function toMills(cryptoAmount: string) {
  return BigNumber(cryptoAmount).multipliedBy(1_000_000).dp(0).toNumber();
}

export function toQuoteAmount(cryptoAmount: string, rate: number) {
  return BigNumber(cryptoAmount).multipliedBy(rate).toFixed(2, BigNumber.ROUND_DOWN);
}

export function formatFiat(amount: string | number, currency: string, locale: string) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  })
    .formatToParts(Number(amount))
    .filter((p) => p.type === 'integer' || p.type === 'decimal' || p.type === 'fraction')
    .map((p) => p.value)
    .join('');
}
