/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CurrenciesCryptoCurrenciesInner
 */
export interface CurrenciesCryptoCurrenciesInner {
    /**
     * 
     * @type {string}
     * @memberof CurrenciesCryptoCurrenciesInner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CurrenciesCryptoCurrenciesInner
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CurrenciesCryptoCurrenciesInner
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CurrenciesCryptoCurrenciesInner
     */
    decimals?: number;
    /**
     * 
     * @type {string}
     * @memberof CurrenciesCryptoCurrenciesInner
     */
    networkName: string;
    /**
     * 
     * @type {number}
     * @memberof CurrenciesCryptoCurrenciesInner
     */
    minAmount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CurrenciesCryptoCurrenciesInner
     */
    imageUrl: string;
    /**
     * 
     * @type {number}
     * @memberof CurrenciesCryptoCurrenciesInner
     */
    defaultAmount?: number;
}

/**
 * Check if a given object implements the CurrenciesCryptoCurrenciesInner interface.
 */
export function instanceOfCurrenciesCryptoCurrenciesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "networkName" in value;
    isInstance = isInstance && "imageUrl" in value;

    return isInstance;
}

export function CurrenciesCryptoCurrenciesInnerFromJSON(json: any): CurrenciesCryptoCurrenciesInner {
    return CurrenciesCryptoCurrenciesInnerFromJSONTyped(json, false);
}

export function CurrenciesCryptoCurrenciesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrenciesCryptoCurrenciesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'name': json['name'],
        'decimals': !exists(json, 'decimals') ? undefined : json['decimals'],
        'networkName': json['networkName'],
        'minAmount': !exists(json, 'minAmount') ? undefined : json['minAmount'],
        'imageUrl': json['imageUrl'],
        'defaultAmount': !exists(json, 'defaultAmount') ? undefined : json['defaultAmount'],
    };
}

export function CurrenciesCryptoCurrenciesInnerToJSON(value?: CurrenciesCryptoCurrenciesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'name': value.name,
        'decimals': value.decimals,
        'networkName': value.networkName,
        'minAmount': value.minAmount,
        'imageUrl': value.imageUrl,
        'defaultAmount': value.defaultAmount,
    };
}

