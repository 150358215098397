/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface PaymentInstrumentResponse
 */
export interface PaymentInstrumentResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentResponse
     */
    applicantId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentResponse
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrumentResponse
     */
    transactionId: string;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof PaymentInstrumentResponse
     */
    paymentMethod: PaymentMethod;
}

/**
 * Check if a given object implements the PaymentInstrumentResponse interface.
 */
export function instanceOfPaymentInstrumentResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "applicantId" in value;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "transactionId" in value;
    isInstance = isInstance && "paymentMethod" in value;

    return isInstance;
}

export function PaymentInstrumentResponseFromJSON(json: any): PaymentInstrumentResponse {
    return PaymentInstrumentResponseFromJSONTyped(json, false);
}

export function PaymentInstrumentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentInstrumentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'applicantId': json['applicantId'],
        'reference': json['reference'],
        'transactionId': json['transactionId'],
        'paymentMethod': PaymentMethodFromJSON(json['paymentMethod']),
    };
}

export function PaymentInstrumentResponseToJSON(value?: PaymentInstrumentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'applicantId': value.applicantId,
        'reference': value.reference,
        'transactionId': value.transactionId,
        'paymentMethod': PaymentMethodToJSON(value.paymentMethod),
    };
}

