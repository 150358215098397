import { inject } from 'vue';
import { themeInjectionKey } from '@simplex/simplex-ui';

export function useTheme() {
  const theme = inject(themeInjectionKey);
  if (!theme) {
    throw new Error('Theme is not available');
  }
  return theme;
}
