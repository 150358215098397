/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InitiateSellAcountDetails } from './InitiateSellAcountDetails';
import {
    InitiateSellAcountDetailsFromJSON,
    InitiateSellAcountDetailsFromJSONTyped,
    InitiateSellAcountDetailsToJSON,
} from './InitiateSellAcountDetails';
import type { InitiateSellTransactionDetails } from './InitiateSellTransactionDetails';
import {
    InitiateSellTransactionDetailsFromJSON,
    InitiateSellTransactionDetailsFromJSONTyped,
    InitiateSellTransactionDetailsToJSON,
} from './InitiateSellTransactionDetails';

/**
 * 
 * @export
 * @interface InitiateSell
 */
export interface InitiateSell {
    /**
     * URL-like value
     * @type {string}
     * @memberof InitiateSell
     */
    refererUrl: string;
    /**
     * URL-like value
     * @type {string}
     * @memberof InitiateSell
     */
    returnUrl?: string;
    /**
     * URL-like value
     * @type {string}
     * @memberof InitiateSell
     */
    deepLink?: string;
    /**
     * 
     * @type {InitiateSellTransactionDetails}
     * @memberof InitiateSell
     */
    txnDetails: InitiateSellTransactionDetails;
    /**
     * 
     * @type {InitiateSellAcountDetails}
     * @memberof InitiateSell
     */
    accountDetails?: InitiateSellAcountDetails;
}

/**
 * Check if a given object implements the InitiateSell interface.
 */
export function instanceOfInitiateSell(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "refererUrl" in value;
    isInstance = isInstance && "txnDetails" in value;

    return isInstance;
}

export function InitiateSellFromJSON(json: any): InitiateSell {
    return InitiateSellFromJSONTyped(json, false);
}

export function InitiateSellFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitiateSell {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'refererUrl': json['referer_url'],
        'returnUrl': !exists(json, 'return_url') ? undefined : json['return_url'],
        'deepLink': !exists(json, 'deep_link') ? undefined : json['deep_link'],
        'txnDetails': InitiateSellTransactionDetailsFromJSON(json['txn_details']),
        'accountDetails': !exists(json, 'account_details') ? undefined : InitiateSellAcountDetailsFromJSON(json['account_details']),
    };
}

export function InitiateSellToJSON(value?: InitiateSell | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referer_url': value.refererUrl,
        'return_url': value.returnUrl,
        'deep_link': value.deepLink,
        'txn_details': InitiateSellTransactionDetailsToJSON(value.txnDetails),
        'account_details': InitiateSellAcountDetailsToJSON(value.accountDetails),
    };
}

