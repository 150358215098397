<template>
  <svg width="45" height="15" fill="none" viewBox="0 0 45 15">
    <path
      fill="#172B85"
      fill-rule="evenodd"
      d="M11.121 14.8h-3.85L4.386 3.683c-.137-.512-.428-.964-.856-1.177A12.449 12.449 0 0 0 0 1.328V.9h6.202a1.62 1.62 0 0 1 1.604 1.39l1.498 8.02L13.152.9h3.743L11.12 14.8m7.911 0h-3.635L18.39.9h3.636l-2.994 13.9m7.7-10.05c.107-.748.749-1.176 1.498-1.176 1.177-.108 2.459.107 3.529.641l.642-2.993A9.127 9.127 0 0 0 29.086.58c-3.529 0-6.097 1.926-6.097 4.598 0 2.033 1.82 3.1 3.103 3.744 1.389.64 1.924 1.069 1.817 1.71 0 .962-1.07 1.39-2.138 1.39-1.284 0-2.568-.32-3.743-.856l-.642 2.995c1.284.533 2.673.748 3.957.748 3.957.106 6.416-1.818 6.416-4.705 0-3.636-5.027-3.85-5.027-5.452ZM44.484 14.8 41.597.9h-3.1c-.643 0-1.285.428-1.498 1.069l-5.346 12.832h3.742l.748-2.031h4.598l.428 2.03h3.315M39.033 4.642l1.068 5.24h-2.994l1.926-5.24"
      clip-rule="evenodd"
    />
  </svg>
</template>
