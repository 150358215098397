/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TransactionCryptoLiquidationProcessStatus } from './TransactionCryptoLiquidationProcessStatus';
import {
    TransactionCryptoLiquidationProcessStatusFromJSON,
    TransactionCryptoLiquidationProcessStatusFromJSONTyped,
    TransactionCryptoLiquidationProcessStatusToJSON,
} from './TransactionCryptoLiquidationProcessStatus';

/**
 * 
 * @export
 * @interface TransactionCryptoLiquidationProcess
 */
export interface TransactionCryptoLiquidationProcess {
    /**
     * 
     * @type {TransactionCryptoLiquidationProcessStatus}
     * @memberof TransactionCryptoLiquidationProcess
     */
    status: TransactionCryptoLiquidationProcessStatus;
    /**
     * 
     * @type {string}
     * @memberof TransactionCryptoLiquidationProcess
     */
    cryptoLiquidationId?: string;
}

/**
 * Check if a given object implements the TransactionCryptoLiquidationProcess interface.
 */
export function instanceOfTransactionCryptoLiquidationProcess(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function TransactionCryptoLiquidationProcessFromJSON(json: any): TransactionCryptoLiquidationProcess {
    return TransactionCryptoLiquidationProcessFromJSONTyped(json, false);
}

export function TransactionCryptoLiquidationProcessFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionCryptoLiquidationProcess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': TransactionCryptoLiquidationProcessStatusFromJSON(json['status']),
        'cryptoLiquidationId': !exists(json, 'cryptoLiquidationId') ? undefined : json['cryptoLiquidationId'],
    };
}

export function TransactionCryptoLiquidationProcessToJSON(value?: TransactionCryptoLiquidationProcess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': TransactionCryptoLiquidationProcessStatusToJSON(value.status),
        'cryptoLiquidationId': value.cryptoLiquidationId,
    };
}

