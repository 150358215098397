/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request to create SEPA payment instrument
 * @export
 * @interface CreateSepaPaymentInstrumentRequest
 */
export interface CreateSepaPaymentInstrumentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSepaPaymentInstrumentRequest
     */
    transactionId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSepaPaymentInstrumentRequest
     */
    iban: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSepaPaymentInstrumentRequest
     */
    bankName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSepaPaymentInstrumentRequest
     */
    bankBic: string;
}

/**
 * Check if a given object implements the CreateSepaPaymentInstrumentRequest interface.
 */
export function instanceOfCreateSepaPaymentInstrumentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "transactionId" in value;
    isInstance = isInstance && "iban" in value;
    isInstance = isInstance && "bankName" in value;
    isInstance = isInstance && "bankBic" in value;

    return isInstance;
}

export function CreateSepaPaymentInstrumentRequestFromJSON(json: any): CreateSepaPaymentInstrumentRequest {
    return CreateSepaPaymentInstrumentRequestFromJSONTyped(json, false);
}

export function CreateSepaPaymentInstrumentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSepaPaymentInstrumentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionId': json['transactionId'],
        'iban': json['iban'],
        'bankName': json['bankName'],
        'bankBic': json['bankBic'],
    };
}

export function CreateSepaPaymentInstrumentRequestToJSON(value?: CreateSepaPaymentInstrumentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactionId': value.transactionId,
        'iban': value.iban,
        'bankName': value.bankName,
        'bankBic': value.bankBic,
    };
}

