import { ref } from 'vue';

export function useRetry(retry: () => void | Promise<unknown>) {
  const canRetry = ref(true);
  return {
    canRetry,
    onRetry: async () => {
      if (!canRetry.value) {
        return;
      }

      try {
        canRetry.value = false;
        await retry();
      } catch (err) {
        console.error(err);
      } finally {
        canRetry.value = true;
      }
    },
  };
}
