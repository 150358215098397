export enum HeaderName {
  AUTHORIZATION = 'Authorization',
  API_KEY = 'x-api-key',
  CORRELATION_ID = 'x-correlation-id',
  IDEMPOTENCE_KEY = 'x-idempotency-key',
  POWERED_BY = 'x-powered-by',
  COUNTRY_CODE = 'x-country-code',
  REQUEST_ID = 'x-request-id',
  PREFER = 'Prefer',
  PREFERENCE_APPLIED = 'Preference-Applied',
}
