/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { KycVerificationStatus } from './KycVerificationStatus';
import {
    KycVerificationStatusFromJSON,
    KycVerificationStatusFromJSONTyped,
    KycVerificationStatusToJSON,
} from './KycVerificationStatus';

/**
 * 
 * @export
 * @interface KycInquiryResponse
 */
export interface KycInquiryResponse {
    /**
     * 
     * @type {string}
     * @memberof KycInquiryResponse
     */
    inquiryId: string;
    /**
     * 
     * @type {string}
     * @memberof KycInquiryResponse
     */
    uiContext: string;
    /**
     * 
     * @type {KycVerificationStatus}
     * @memberof KycInquiryResponse
     */
    status: KycVerificationStatus;
}

/**
 * Check if a given object implements the KycInquiryResponse interface.
 */
export function instanceOfKycInquiryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "inquiryId" in value;
    isInstance = isInstance && "uiContext" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function KycInquiryResponseFromJSON(json: any): KycInquiryResponse {
    return KycInquiryResponseFromJSONTyped(json, false);
}

export function KycInquiryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KycInquiryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inquiryId': json['inquiryId'],
        'uiContext': json['uiContext'],
        'status': KycVerificationStatusFromJSON(json['status']),
    };
}

export function KycInquiryResponseToJSON(value?: KycInquiryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inquiryId': value.inquiryId,
        'uiContext': value.uiContext,
        'status': KycVerificationStatusToJSON(value.status),
    };
}

