/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransferInstruction
 */
export interface TransferInstruction {
    /**
     * crypto amount. In major units. I.e. 3.4565432344
     * @type {string}
     * @memberof TransferInstruction
     */
    cryptoAmount: string;
    /**
     * Crypto currency with optional network identifier attached.
     * List of crypto currencies Simplex might send you:
     * - USDT
     * - TRX
     * - BTC
     * - USDP
     * - AAVE
     * - USDC
     * - UNI
     * - COTI-ERC20
     * - TUSD
     * - COTI
     * - BUSD
     * - HTR
     * - USDT-TRC20
     * @type {string}
     * @memberof TransferInstruction
     */
    cryptoCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof TransferInstruction
     */
    liquidityProvider: string;
}

/**
 * Check if a given object implements the TransferInstruction interface.
 */
export function instanceOfTransferInstruction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cryptoAmount" in value;
    isInstance = isInstance && "cryptoCurrency" in value;
    isInstance = isInstance && "liquidityProvider" in value;

    return isInstance;
}

export function TransferInstructionFromJSON(json: any): TransferInstruction {
    return TransferInstructionFromJSONTyped(json, false);
}

export function TransferInstructionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferInstruction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cryptoAmount': json['cryptoAmount'],
        'cryptoCurrency': json['cryptoCurrency'],
        'liquidityProvider': json['liquidityProvider'],
    };
}

export function TransferInstructionToJSON(value?: TransferInstruction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cryptoAmount': value.cryptoAmount,
        'cryptoCurrency': value.cryptoCurrency,
        'liquidityProvider': value.liquidityProvider,
    };
}

