/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SepaPaymentInstrumentResponse
 */
export interface SepaPaymentInstrumentResponse {
    /**
     * 
     * @type {string}
     * @memberof SepaPaymentInstrumentResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SepaPaymentInstrumentResponse
     */
    applicantId: string;
    /**
     * 
     * @type {string}
     * @memberof SepaPaymentInstrumentResponse
     */
    iban: string;
    /**
     * 
     * @type {string}
     * @memberof SepaPaymentInstrumentResponse
     */
    beneficiaryFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof SepaPaymentInstrumentResponse
     */
    beneficiaryBankName: string;
    /**
     * 
     * @type {string}
     * @memberof SepaPaymentInstrumentResponse
     */
    beneficiaryBankBic: string;
    /**
     * 
     * @type {string}
     * @memberof SepaPaymentInstrumentResponse
     */
    transactionId?: string;
}

/**
 * Check if a given object implements the SepaPaymentInstrumentResponse interface.
 */
export function instanceOfSepaPaymentInstrumentResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "applicantId" in value;
    isInstance = isInstance && "iban" in value;
    isInstance = isInstance && "beneficiaryBankName" in value;
    isInstance = isInstance && "beneficiaryBankBic" in value;

    return isInstance;
}

export function SepaPaymentInstrumentResponseFromJSON(json: any): SepaPaymentInstrumentResponse {
    return SepaPaymentInstrumentResponseFromJSONTyped(json, false);
}

export function SepaPaymentInstrumentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SepaPaymentInstrumentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'applicantId': json['applicantId'],
        'iban': json['iban'],
        'beneficiaryFullName': !exists(json, 'beneficiaryFullName') ? undefined : json['beneficiaryFullName'],
        'beneficiaryBankName': json['beneficiaryBankName'],
        'beneficiaryBankBic': json['beneficiaryBankBic'],
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
    };
}

export function SepaPaymentInstrumentResponseToJSON(value?: SepaPaymentInstrumentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'applicantId': value.applicantId,
        'iban': value.iban,
        'beneficiaryFullName': value.beneficiaryFullName,
        'beneficiaryBankName': value.beneficiaryBankName,
        'beneficiaryBankBic': value.beneficiaryBankBic,
        'transactionId': value.transactionId,
    };
}

