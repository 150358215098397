<template>
  <svg width="57" height="16" fill="none" viewBox="0 0 57 16">
    <path
      fill="#10298E"
      fill-rule="evenodd"
      d="M29.69 15.053V.316h7.613c1.044 0 1.841.083 2.389.248 1.252.38 2.092 1.16 2.522 2.339.222.618.334 1.55.334 2.793 0 1.495-.124 2.569-.367 3.223-.489 1.293-1.494 2.038-3.012 2.231-.178.03-.937.055-2.278.076l-.678.022h-2.436v3.805zm4.085-7.224h2.547c.808-.028 1.299-.088 1.476-.183.241-.129.403-.387.484-.774.052-.257.077-.64.077-1.15 0-.622-.05-1.084-.154-1.386-.147-.423-.499-.68-1.056-.774a8.51 8.51 0 0 0-.793-.021h-2.581v4.288"
      clip-rule="evenodd"
    />
    <path
      fill="#10298E"
      d="M12.974 5.017H9.173c0-.606-.074-1.012-.22-1.219-.226-.306-.85-.46-1.872-.46-.992 0-1.65.089-1.971.265-.321.177-.482.562-.482 1.155 0 .537.142.89.427 1.06.205.12.475.19.811.211l.767.053c1.643.105 2.669.18 3.078.222 1.3.127 2.241.463 2.826 1.006.46.424.734.979.821 1.663.052.41.077.873.077 1.387 0 1.187-.117 2.056-.35 2.606-.423 1.01-1.374 1.649-2.854 1.917-.62.113-1.568.17-2.843.17-2.129 0-3.613-.123-4.45-.37-1.028-.303-1.706-.917-2.034-1.84C.72 12.327.63 11.47.63 10.272h3.8l.001.306c0 .64.19 1.051.568 1.234.262.127.579.193.95.2h1.398c.713 0 1.168-.035 1.365-.106.35-.133.578-.348.688-.645.058-.183.087-.42.087-.71 0-.648-.244-1.043-.731-1.184-.182-.056-1.03-.134-2.543-.233C5 9.05 4.155 8.97 3.683 8.89c-1.245-.226-2.074-.69-2.488-1.396-.364-.6-.546-1.506-.546-2.72 0-.924.098-1.664.295-2.222.197-.557.514-.98.951-1.27C2.535.84 3.355.578 4.354.5 5.184.43 6.106.394 7.119.394c1.596 0 2.733.089 3.41.265 1.655.432 2.482 1.64 2.482 3.626 0 .163-.012.406-.037.732"
    />
    <path
      fill="#10298E"
      fill-rule="evenodd"
      d="M45.806 12.506h5.463l.78 2.547h4.138L51.569.316h-6.163l-4.562 14.737h4.227l.735-2.547m2.737-9.26 1.914 6.388h-3.772l1.858-6.388"
      clip-rule="evenodd"
    />
    <path
      fill="#FFBE00"
      fill-rule="evenodd"
      d="M24.05 2.388c1.685 0 3.19.754 4.178 1.934l.88-1.833a7.848 7.848 0 0 0-5.32-2.043c-3.22 0-5.973 1.887-7.104 4.559h-1.717l-1.014 2.11h2.21a6.89 6.89 0 0 0 .01 1.309h-1.111l-1.014 2.111h2.689c1.163 2.604 3.882 4.432 7.052 4.432a7.913 7.913 0 0 0 4.306-1.257v-2.588a5.44 5.44 0 0 1-4.045 1.783 5.424 5.424 0 0 1-4.505-2.37h5.63l1.015-2.111h-7.474a5.188 5.188 0 0 1-.032-1.309h8.134l1.014-2.11h-8.444a5.415 5.415 0 0 1 4.662-2.617"
      clip-rule="evenodd"
    />
  </svg>
</template>
