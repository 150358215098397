export function createConfig() {
  return {
    PAYMENT_METHOD: undefined,
    FIAT_MIN_AMOUNT: 50,
    FIAT_MAX_AMOUNT: 5000,
    DEFAULTS: {
      cryptoAmount: '0.015',
      cryptoCurrency: 'BTC',
      fiatAmount: '',
      fiatCurrency: 'EUR',
      defaultCryptoAmounts: [
        { value: '0.015', crypto: 'BTC' },
        { value: '500', crypto: 'USDT-TRC20' },
      ],
    },
  } as const;
}

export type Config = ReturnType<typeof createConfig>;
