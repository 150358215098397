/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Crypto currency and amount
 * @export
 * @interface CryptoMoney
 */
export interface CryptoMoney {
    /**
     * crypto amount. In major units. I.e. 3.4565432344
     * @type {string}
     * @memberof CryptoMoney
     */
    amount: string;
    /**
     * Crypto currency with optional network identifier attached.
     * List of crypto currencies Simplex might send you:
     * - USDT
     * - TRX
     * - BTC
     * - USDP
     * - AAVE
     * - USDC
     * - UNI
     * - COTI-ERC20
     * - TUSD
     * - COTI
     * - BUSD
     * - HTR
     * - USDT-TRC20
     * @type {string}
     * @memberof CryptoMoney
     */
    currency: string;
}

/**
 * Check if a given object implements the CryptoMoney interface.
 */
export function instanceOfCryptoMoney(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "currency" in value;

    return isInstance;
}

export function CryptoMoneyFromJSON(json: any): CryptoMoney {
    return CryptoMoneyFromJSONTyped(json, false);
}

export function CryptoMoneyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CryptoMoney {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'currency': json['currency'],
    };
}

export function CryptoMoneyToJSON(value?: CryptoMoney | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'currency': value.currency,
    };
}

