import { VueQueryPlugin } from '@tanstack/vue-query';
import { createApp } from 'vue';
import { Configuration, ExternalApi, TransactionApi } from '@simplex/rnd.api-client-sell-checkout';
import { HeaderName } from '@simplex/rnd.header';
import { createSimplexUI } from '@simplex/simplex-ui';
import { SellCheckoutApi } from './app/api/api';
import { apiKey } from './app/api/use-api';
import { translationConfig } from './app/translations/config';
import App from './App.vue';
import './style.css';

const app = createApp(App);
app.use(createSimplexUI({}));
app.use(translationConfig());
app.use(VueQueryPlugin);
app.provide(
  apiKey,
  new SellCheckoutApi(
    new TransactionApi(
      new Configuration({
        basePath: '/api',
        headers: window.__user_country__ ? { [HeaderName.COUNTRY_CODE]: window.__user_country__ } : {},
      }),
    ),
    new ExternalApi(
      new Configuration({
        basePath: import.meta.env.VITE_SELL_CHECKOUT_URL ?? '',
        headers: window.__user_country__ ? { [HeaderName.COUNTRY_CODE]: window.__user_country__ } : {},
      }),
    ),
  ),
);

app.mount('#root');
