/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PersonalDetailsAddressesInner } from './PersonalDetailsAddressesInner';
import {
    PersonalDetailsAddressesInnerFromJSON,
    PersonalDetailsAddressesInnerFromJSONTyped,
    PersonalDetailsAddressesInnerToJSON,
} from './PersonalDetailsAddressesInner';

/**
 * 
 * @export
 * @interface AccountDetailsPersonalDetails
 */
export interface AccountDetailsPersonalDetails {
    /**
     * The first name on the account.
     * @type {string}
     * @memberof AccountDetailsPersonalDetails
     */
    firstName?: string;
    /**
     * The last name on the account.
     * @type {string}
     * @memberof AccountDetailsPersonalDetails
     */
    lastName?: string;
    /**
     * A list of account email addresses, starting with the account’s primary email address.
     * @type {Array<string>}
     * @memberof AccountDetailsPersonalDetails
     */
    emails: Array<string>;
    /**
     * A list of account phone numbers, starting with the account’s primary phone number.
     * @type {Array<string>}
     * @memberof AccountDetailsPersonalDetails
     */
    phones: Array<string>;
    /**
     * A list of account mailing addresses, starting with the account’s primary address.
     * @type {Array<PersonalDetailsAddressesInner>}
     * @memberof AccountDetailsPersonalDetails
     */
    addresses: Array<PersonalDetailsAddressesInner>;
}

/**
 * Check if a given object implements the AccountDetailsPersonalDetails interface.
 */
export function instanceOfAccountDetailsPersonalDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "emails" in value;
    isInstance = isInstance && "phones" in value;
    isInstance = isInstance && "addresses" in value;

    return isInstance;
}

export function AccountDetailsPersonalDetailsFromJSON(json: any): AccountDetailsPersonalDetails {
    return AccountDetailsPersonalDetailsFromJSONTyped(json, false);
}

export function AccountDetailsPersonalDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountDetailsPersonalDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'emails': json['emails'],
        'phones': json['phones'],
        'addresses': ((json['addresses'] as Array<any>).map(PersonalDetailsAddressesInnerFromJSON)),
    };
}

export function AccountDetailsPersonalDetailsToJSON(value?: AccountDetailsPersonalDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'emails': value.emails,
        'phones': value.phones,
        'addresses': ((value.addresses as Array<any>).map(PersonalDetailsAddressesInnerToJSON)),
    };
}

