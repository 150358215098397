/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CryptoTransferStatus = {
    Initiated: 'initiated',
    Pending: 'pending',
    Complete: 'complete',
    ConsentAgreed: 'consentAgreed'
} as const;
export type CryptoTransferStatus = typeof CryptoTransferStatus[keyof typeof CryptoTransferStatus];


export function CryptoTransferStatusFromJSON(json: any): CryptoTransferStatus {
    return CryptoTransferStatusFromJSONTyped(json, false);
}

export function CryptoTransferStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CryptoTransferStatus {
    return json as CryptoTransferStatus;
}

export function CryptoTransferStatusToJSON(value?: CryptoTransferStatus | null): any {
    return value as any;
}

