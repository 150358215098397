<template>
  <svg width="63" height="40" fill="none" viewBox="0 0 63 40">
    <path
      fill="#000"
      fill-rule="evenodd"
      d="M29.858 25.175v-5.38h2.988c1.224 0 2.257-.38 3.1-1.132l.202-.19c1.538-1.557 1.437-3.982-.203-5.422-.82-.763-1.943-1.176-3.1-1.155H28.05v13.28h1.808m0-7.01v-4.638h3.033c.651 0 1.27.233 1.73.657.977.89 1 2.372.056 3.293a2.44 2.44 0 0 1-1.786.689h-3.034m14.725-1.368c-.775-.667-1.83-1.006-3.167-1.006-1.719 0-3.01.593-3.864 1.769l1.595.942c.584-.805 1.381-1.207 2.392-1.207.64 0 1.258.222 1.741.625.472.381.741.932.741 1.514v.392c-.696-.36-1.572-.55-2.65-.55-1.258 0-2.27.274-3.022.836-.752.561-1.134 1.302-1.134 2.245-.023.857.37 1.673 1.067 2.223.708.593 1.606.89 2.662.89 1.246 0 2.235-.519 2.987-1.557h.08v1.26h1.729v-5.601c0-1.176-.382-2.107-1.157-2.775m-4.907 6.704a1.35 1.35 0 0 1-.596-1.112c0-.497.248-.91.73-1.239.495-.328 1.113-.498 1.843-.498 1.011-.01 1.798.202 2.36.625 0 .72-.304 1.345-.9 1.875a2.965 2.965 0 0 1-2.033.794c-.505.01-1-.148-1.404-.445m9.95 5.666 6.042-13.078h-1.965l-2.797 6.523h-.034l-2.864-6.523h-1.965l3.964 8.504-2.246 4.574h1.864"
      clip-rule="evenodd"
    />
    <path
      fill="#4285F4"
      d="M23.615 18.628c0-.52-.045-1.038-.135-1.547h-7.628v2.934h4.37a3.525 3.525 0 0 1-1.617 2.32v1.906h2.606c1.527-1.324 2.404-3.283 2.404-5.613"
    />
    <path
      fill="#34A853"
      d="M15.852 26.082c2.18 0 4.021-.678 5.358-1.843l-2.606-1.906c-.73.466-1.662.73-2.752.73-2.111 0-3.897-1.344-4.537-3.144H8.63v1.97c1.37 2.573 4.167 4.193 7.222 4.193"
    />
    <path
      fill="#FBBC04"
      d="M11.315 19.92a4.352 4.352 0 0 1 0-2.923v-1.959H8.63a7.175 7.175 0 0 0 0 6.841l2.685-1.959"
    />
    <path
      fill="#EA4335"
      d="M15.851 13.852a4.5 4.5 0 0 1 3.1 1.143l2.314-2.18c-1.471-1.293-3.403-2.002-5.414-1.981-3.055 0-5.851 1.63-7.222 4.204l2.685 1.97c.64-1.811 2.426-3.156 4.537-3.156"
    />
  </svg>
</template>
