/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FiatPayoutStatus } from './FiatPayoutStatus';
import {
    FiatPayoutStatusFromJSON,
    FiatPayoutStatusFromJSONTyped,
    FiatPayoutStatusToJSON,
} from './FiatPayoutStatus';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * Fiat payout aggregate
 * @export
 * @interface FiatPayout
 */
export interface FiatPayout {
    /**
     * Fiat payout identifier
     * @type {string}
     * @memberof FiatPayout
     */
    id: string;
    /**
     * 
     * @type {FiatPayoutStatus}
     * @memberof FiatPayout
     */
    status: FiatPayoutStatus;
    /**
     * transaction id
     * @type {string}
     * @memberof FiatPayout
     */
    transactionId: string;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof FiatPayout
     */
    paymentMethod: PaymentMethod;
    /**
     * returned if status === complete. indicates if the fiat payout process finished successfully or not
     * 
     * @type {string}
     * @memberof FiatPayout
     */
    result?: FiatPayoutResult;
}


/**
 * @export
 */
export const FiatPayoutResult = {
    Success: 'success',
    Fail: 'fail'
} as const;
export type FiatPayoutResult = typeof FiatPayoutResult[keyof typeof FiatPayoutResult];


/**
 * Check if a given object implements the FiatPayout interface.
 */
export function instanceOfFiatPayout(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "transactionId" in value;
    isInstance = isInstance && "paymentMethod" in value;

    return isInstance;
}

export function FiatPayoutFromJSON(json: any): FiatPayout {
    return FiatPayoutFromJSONTyped(json, false);
}

export function FiatPayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): FiatPayout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': FiatPayoutStatusFromJSON(json['status']),
        'transactionId': json['transactionId'],
        'paymentMethod': PaymentMethodFromJSON(json['paymentMethod']),
        'result': !exists(json, 'result') ? undefined : json['result'],
    };
}

export function FiatPayoutToJSON(value?: FiatPayout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': FiatPayoutStatusToJSON(value.status),
        'transactionId': value.transactionId,
        'paymentMethod': PaymentMethodToJSON(value.paymentMethod),
        'result': value.result,
    };
}

