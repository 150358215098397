/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransactionFiatPayoutProcess
 */
export interface TransactionFiatPayoutProcess {
    /**
     * process status
     * @type {string}
     * @memberof TransactionFiatPayoutProcess
     */
    status: TransactionFiatPayoutProcessStatus;
    /**
     * 
     * @type {string}
     * @memberof TransactionFiatPayoutProcess
     */
    fiatPayoutId: string;
}


/**
 * @export
 */
export const TransactionFiatPayoutProcessStatus = {
    Initiated: 'initiated',
    Finished: 'finished',
    Failed: 'failed',
    Processing: 'processing'
} as const;
export type TransactionFiatPayoutProcessStatus = typeof TransactionFiatPayoutProcessStatus[keyof typeof TransactionFiatPayoutProcessStatus];


/**
 * Check if a given object implements the TransactionFiatPayoutProcess interface.
 */
export function instanceOfTransactionFiatPayoutProcess(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "fiatPayoutId" in value;

    return isInstance;
}

export function TransactionFiatPayoutProcessFromJSON(json: any): TransactionFiatPayoutProcess {
    return TransactionFiatPayoutProcessFromJSONTyped(json, false);
}

export function TransactionFiatPayoutProcessFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionFiatPayoutProcess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'fiatPayoutId': json['fiatPayoutId'],
    };
}

export function TransactionFiatPayoutProcessToJSON(value?: TransactionFiatPayoutProcess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'fiatPayoutId': value.fiatPayoutId,
    };
}

