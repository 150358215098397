import { reactive, ref } from 'vue';
import { useFeatureFlags } from '../app/feature-flags';

export function useRedirectTimeout() {
  const featureFlags = useFeatureFlags();
  const show = ref(false);
  const loading = ref(false);
  return reactive({
    show,
    loading,
    handle: () => {
      loading.value = true;
      const intervalId = window.setInterval(
        () => {
          if (!show.value) {
            show.value = true;
            loading.value = false;
            clearInterval(intervalId);
          }
        },
        featureFlags.widgetFallbackLinkNoInterval === 'true' ? 0 : 500,
      );
    },
  });
}
