/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { KycCollectionStatus } from './KycCollectionStatus';
import {
    KycCollectionStatusFromJSON,
    KycCollectionStatusFromJSONTyped,
    KycCollectionStatusToJSON,
} from './KycCollectionStatus';

/**
 * 
 * @export
 * @interface KycCollectionResponse
 */
export interface KycCollectionResponse {
    /**
     * 
     * @type {string}
     * @memberof KycCollectionResponse
     */
    inquiryId: string;
    /**
     * 
     * @type {KycCollectionStatus}
     * @memberof KycCollectionResponse
     */
    status: KycCollectionStatus;
}

/**
 * Check if a given object implements the KycCollectionResponse interface.
 */
export function instanceOfKycCollectionResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "inquiryId" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function KycCollectionResponseFromJSON(json: any): KycCollectionResponse {
    return KycCollectionResponseFromJSONTyped(json, false);
}

export function KycCollectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KycCollectionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inquiryId': json['inquiryId'],
        'status': KycCollectionStatusFromJSON(json['status']),
    };
}

export function KycCollectionResponseToJSON(value?: KycCollectionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inquiryId': value.inquiryId,
        'status': KycCollectionStatusToJSON(value.status),
    };
}

