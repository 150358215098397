export default {
  widget: {
    'desktop-heading': 'Sell your crypto now with just a few clicks',
    'sell-title': 'Sell',
    'get-title': 'Get',
    'sell-button-title': 'Sell Now',
    'sell-link-title': 'Button not working? Click here.',
    loading: 'Loading...',
    validation: {
      required: 'Please enter a {fieldName} amount.',
      'should-be-number': 'Please enter a number for the amount.',
      'should-be-greater': 'The amount entered, {amount}{fiatCurrency}, must be more than {minValue}{fiatCurrency}.',
      'should-be-less': 'The amount entered, {amount}{fiatCurrency}, must be less than {maxValue}{fiatCurrency}.',
      'enter-valid-number': 'Please enter a valid number for the amount.',
      'error-amount-too-high':
        'The entered crypto amount exceeds the maximum allowed limit of {maxValue}. Please enter a lower amount.',
      'error-amount-too-low':
        'The entered crypto amount is below the minimum allowed limit of {minValue}. Please enter a higher amount.',
      'error-invalid-amount':
        'The amount is not valid. Please enter a different amount for this cryptocurrency and try again.',
      'error-invalid-fiat-amount': 'The amount is not valid.',
    },
    'rate-message': '{cryptoCurrency} price: {price} {fiatCurrency}.',
    support: {
      'need-support-contact': 'Need support? Contact {emailLink}',
      email: "support{'@'}simplex.com",
    },
  },
  common: {
    'unknown-error': 'Something Went Wrong',
    'try-again-later': 'Please try again later',
    'try-again': 'Please try again',
    'unknown-error-description': 'We apologize for any inconvenience, but an unexpected error occurred.',
  },
};
