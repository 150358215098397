import { inject, type InjectionKey } from 'vue';
import type { IWidgetApi } from './api';

export const apiKey: InjectionKey<IWidgetApi> = Symbol('api');

export function useApi() {
  const api = inject(apiKey);
  if (!api) {
    throw new Error('api not provided');
  }

  return api;
}
