import { reactive } from 'vue';
import { useStorage } from '../storage/use-storage';

export type FeatureFlags = ReturnType<typeof useFeatureFlags>;

export const useFeatureFlags = () => {
  const widgetFallbackLinkNoInterval = useStorage('widgetFallbackLinkNoInterval');
  return reactive({
    widgetFallbackLinkNoInterval,
  });
};
