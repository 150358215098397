<template>
  <svg width="63" height="40" fill="none" viewBox="0 0 63 40">
    <g fill-rule="evenodd" clip-rule="evenodd">
      <path
        fill="#ED0006"
        d="M31.618 26.812a11.035 11.035 0 0 1-7 2.469c-5.961 0-10.793-4.626-10.793-10.333S18.657 8.614 24.617 8.614c2.672 0 5.117.93 7.001 2.47a11.036 11.036 0 0 1 7-2.47c5.961 0 10.793 4.627 10.793 10.334s-4.832 10.333-10.792 10.333c-2.672 0-5.117-.93-7.001-2.469"
      />
      <path
        fill="#F9A000"
        d="M31.62 26.813c2.32-1.896 3.791-4.716 3.791-7.865s-1.471-5.97-3.791-7.864a11.035 11.035 0 0 1 7-2.47c5.96 0 10.793 4.627 10.793 10.334S44.58 29.281 38.62 29.281c-2.671 0-5.116-.929-7-2.468"
      />
      <path
        fill="#FF5E00"
        d="M31.619 26.812c-2.32-1.896-3.792-4.716-3.792-7.865 0-3.15 1.47-5.97 3.79-7.864 2.321 1.895 3.793 4.715 3.793 7.864 0 3.15-1.471 5.97-3.791 7.865"
      />
    </g>
  </svg>
</template>
