/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request to create card payment instrument
 * @export
 * @interface CreateCardPaymentInstrumentCollectionRequest
 */
export interface CreateCardPaymentInstrumentCollectionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCardPaymentInstrumentCollectionRequest
     */
    transactionId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCardPaymentInstrumentCollectionRequest
     */
    firstMiddleName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCardPaymentInstrumentCollectionRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCardPaymentInstrumentCollectionRequest
     */
    pan: string;
    /**
     * card expiry date
     * @type {string}
     * @memberof CreateCardPaymentInstrumentCollectionRequest
     */
    expiry: string;
    /**
     * user auth token for recollection
     * @type {string}
     * @memberof CreateCardPaymentInstrumentCollectionRequest
     */
    verificationToken?: string;
}

/**
 * Check if a given object implements the CreateCardPaymentInstrumentCollectionRequest interface.
 */
export function instanceOfCreateCardPaymentInstrumentCollectionRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "transactionId" in value;
    isInstance = isInstance && "firstMiddleName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "pan" in value;
    isInstance = isInstance && "expiry" in value;

    return isInstance;
}

export function CreateCardPaymentInstrumentCollectionRequestFromJSON(json: any): CreateCardPaymentInstrumentCollectionRequest {
    return CreateCardPaymentInstrumentCollectionRequestFromJSONTyped(json, false);
}

export function CreateCardPaymentInstrumentCollectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCardPaymentInstrumentCollectionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactionId': json['transactionId'],
        'firstMiddleName': json['firstMiddleName'],
        'lastName': json['lastName'],
        'pan': json['pan'],
        'expiry': json['expiry'],
        'verificationToken': !exists(json, 'verificationToken') ? undefined : json['verificationToken'],
    };
}

export function CreateCardPaymentInstrumentCollectionRequestToJSON(value?: CreateCardPaymentInstrumentCollectionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactionId': value.transactionId,
        'firstMiddleName': value.firstMiddleName,
        'lastName': value.lastName,
        'pan': value.pan,
        'expiry': value.expiry,
        'verificationToken': value.verificationToken,
    };
}

