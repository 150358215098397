/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LpKycVerificationStatus } from './LpKycVerificationStatus';
import {
    LpKycVerificationStatusFromJSON,
    LpKycVerificationStatusFromJSONTyped,
    LpKycVerificationStatusToJSON,
} from './LpKycVerificationStatus';

/**
 * 
 * @export
 * @interface TransactionLpKycVerification
 */
export interface TransactionLpKycVerification {
    /**
     * 
     * @type {string}
     * @memberof TransactionLpKycVerification
     */
    verificationId: string;
    /**
     * 
     * @type {LpKycVerificationStatus}
     * @memberof TransactionLpKycVerification
     */
    status: LpKycVerificationStatus;
}

/**
 * Check if a given object implements the TransactionLpKycVerification interface.
 */
export function instanceOfTransactionLpKycVerification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "verificationId" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function TransactionLpKycVerificationFromJSON(json: any): TransactionLpKycVerification {
    return TransactionLpKycVerificationFromJSONTyped(json, false);
}

export function TransactionLpKycVerificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionLpKycVerification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'verificationId': json['verificationId'],
        'status': LpKycVerificationStatusFromJSON(json['status']),
    };
}

export function TransactionLpKycVerificationToJSON(value?: TransactionLpKycVerification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'verificationId': value.verificationId,
        'status': LpKycVerificationStatusToJSON(value.status),
    };
}

