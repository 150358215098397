/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LpKycVerificationStatus = {
    Pending: 'pending',
    Completed: 'completed',
    Rejected: 'rejected'
} as const;
export type LpKycVerificationStatus = typeof LpKycVerificationStatus[keyof typeof LpKycVerificationStatus];


export function LpKycVerificationStatusFromJSON(json: any): LpKycVerificationStatus {
    return LpKycVerificationStatusFromJSONTyped(json, false);
}

export function LpKycVerificationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): LpKycVerificationStatus {
    return json as LpKycVerificationStatus;
}

export function LpKycVerificationStatusToJSON(value?: LpKycVerificationStatus | null): any {
    return value as any;
}

