/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CryptoTransferStatus } from './CryptoTransferStatus';
import {
    CryptoTransferStatusFromJSON,
    CryptoTransferStatusFromJSONTyped,
    CryptoTransferStatusToJSON,
} from './CryptoTransferStatus';
import type { TransferInstruction } from './TransferInstruction';
import {
    TransferInstructionFromJSON,
    TransferInstructionFromJSONTyped,
    TransferInstructionToJSON,
} from './TransferInstruction';

/**
 * 
 * @export
 * @interface CryptoTransfer
 */
export interface CryptoTransfer {
    /**
     * crypto transfer identifier
     * @type {string}
     * @memberof CryptoTransfer
     */
    id: string;
    /**
     * 
     * @type {CryptoTransferStatus}
     * @memberof CryptoTransfer
     */
    status: CryptoTransferStatus;
    /**
     * 
     * @type {TransferInstruction}
     * @memberof CryptoTransfer
     */
    transferInstruction?: TransferInstruction;
}

/**
 * Check if a given object implements the CryptoTransfer interface.
 */
export function instanceOfCryptoTransfer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function CryptoTransferFromJSON(json: any): CryptoTransfer {
    return CryptoTransferFromJSONTyped(json, false);
}

export function CryptoTransferFromJSONTyped(json: any, ignoreDiscriminator: boolean): CryptoTransfer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': CryptoTransferStatusFromJSON(json['status']),
        'transferInstruction': !exists(json, 'transferInstruction') ? undefined : TransferInstructionFromJSON(json['transferInstruction']),
    };
}

export function CryptoTransferToJSON(value?: CryptoTransfer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': CryptoTransferStatusToJSON(value.status),
        'transferInstruction': TransferInstructionToJSON(value.transferInstruction),
    };
}

