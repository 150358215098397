/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentInstrumentRecollectionOtpConfirmationResponse
 */
export interface PaymentInstrumentRecollectionOtpConfirmationResponse {
    /**
     * Token to include in payment instrument form request
     * @type {string}
     * @memberof PaymentInstrumentRecollectionOtpConfirmationResponse
     */
    verificationToken?: string;
}

/**
 * Check if a given object implements the PaymentInstrumentRecollectionOtpConfirmationResponse interface.
 */
export function instanceOfPaymentInstrumentRecollectionOtpConfirmationResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaymentInstrumentRecollectionOtpConfirmationResponseFromJSON(json: any): PaymentInstrumentRecollectionOtpConfirmationResponse {
    return PaymentInstrumentRecollectionOtpConfirmationResponseFromJSONTyped(json, false);
}

export function PaymentInstrumentRecollectionOtpConfirmationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentInstrumentRecollectionOtpConfirmationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'verificationToken': !exists(json, 'verificationToken') ? undefined : json['verificationToken'],
    };
}

export function PaymentInstrumentRecollectionOtpConfirmationResponseToJSON(value?: PaymentInstrumentRecollectionOtpConfirmationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'verificationToken': value.verificationToken,
    };
}

