/* tslint:disable */
/* eslint-disable */
/**
 * Sell Checkout API
 * Sell Checkout API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-vilnius@simplex.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailVerificationAttempt } from './EmailVerificationAttempt';
import {
    EmailVerificationAttemptFromJSON,
    EmailVerificationAttemptFromJSONTyped,
    EmailVerificationAttemptToJSON,
} from './EmailVerificationAttempt';

/**
 * 
 * @export
 * @interface EmailVerification
 */
export interface EmailVerification {
    /**
     * 
     * @type {string}
     * @memberof EmailVerification
     */
    id: string;
    /**
     * 
     * @type {EmailVerificationAttempt}
     * @memberof EmailVerification
     */
    attempt: EmailVerificationAttempt;
}

/**
 * Check if a given object implements the EmailVerification interface.
 */
export function instanceOfEmailVerification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "attempt" in value;

    return isInstance;
}

export function EmailVerificationFromJSON(json: any): EmailVerification {
    return EmailVerificationFromJSONTyped(json, false);
}

export function EmailVerificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailVerification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'attempt': EmailVerificationAttemptFromJSON(json['attempt']),
    };
}

export function EmailVerificationToJSON(value?: EmailVerification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'attempt': EmailVerificationAttemptToJSON(value.attempt),
    };
}

